<template>
  <div class="about-container solution-container">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">我们的服务</h1>
        <p class="fs-20">
          我们为
          <b class="fs-20">客户</b>量身定制，同时保持业内最高水平的客户服务。
        </p>
      </div>
      <div class="banner"></div>
      <div class="third">
        <div class="df ptb-100">
          <div><img src="../assets/img/home/cb2.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">空运服务</h2>
            <p class="fs-20">
              凭借我们的专业知识，坚定的服务和具有竞争力的价格，深如美设（上海）供应链科技有限公司为航空货运运输提
              供深入的专业知识以及广泛的灵活可靠的服务。无论您是需要快速空运服务、门到门服务还是绝大多数类型的要求，
              我们都可以安排多种运输选择，以满足您在安全、时间和便利方面的特定需求。
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">海运服务</h2>
            <p class="fs-20">
              凭借数十年的经验，领先速度国际公司提供了一个优越的方式运输。
              通过与海运公司的广泛联系网络，我们能够提供快速、经济和可靠的海运服务。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/home/cb1.jpg" alt="" />
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/home/cb3.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">其他服务</h2>
            <p class="fs-20">铁路</p>
            <p class="fs-20">卡车运输</p>
            <p class="fs-20">定制服务</p>
            <p class="fs-20">仓库储存和分配</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Solution",
  components: {
    Header,
    Footer,
  },
};
</script>
